import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Platform, TextInput } from 'react-native';
import axios from 'axios';
import Icon from 'react-native-vector-icons/MaterialIcons';

import DocsMenu from './Components/DocsMenu';

export default function StartHereDocs({ host, appInfo, InAppNavigation }) {
    const { goToSend } = InAppNavigation;

    const [showClassInstructions, setShowClassInstructions] = useState(false);
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');

    const date = new Date();
    let hour = date.getHours();
    let amPM = "";

    if (hour === 12) {
        amPM = "PM"
    } else if (hour > 12) {
        amPM = "PM"
        hour = hour - 12;
    } else {
        amPM = "AM"
    }

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    const sendNotification = () => {

        const date = new Date();
        let hour = date.getHours();
        let amPM = "";

        if (hour === 12) {
            amPM = "PM"
        } else if (hour > 12) {
            amPM = "PM"
            hour = hour - 12;
        } else {
            amPM = "AM"
        }

        axios
            .post(`${host}/api/notification`, { appId: getCookie('app_id'), title, body, dateSent: (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear() + " " + hour + ":" + ('0' + date.getMinutes()).slice(-2) + amPM })
            .then(res => {
                // console.log(res.data);
                alert(`Your push notification was successfully sent!`);
                setTitle('');
                setBody('');

                // goToSend();
            })
            .catch(err => {
                console.log(err);
                alert("Sorry, your app does not have any push notification subscribers yet. Try opening your app in the Expo Go app, then refresh this page and try again.");
                location.reload();
            })
    }

    return (
        <View style={styles.body}>
            <DocsMenu InAppNavigation={InAppNavigation} />
            {/* <TouchableOpacity style={styles.button} onPress={() => { setShowInstallDocs(false); setShowSendNot(true); setShowInstantInstallDocs(false); }}>
                <Text style={styles.buttonText}>Send a Push Notification</Text>
            </TouchableOpacity> */}

            <Text style={styles.mb}>App ID: {appInfo.app_id}   App Token: '{appInfo.app_token}'</Text>

            <Text style={styles.HeadingText}>Follow these steps to add Native Notify push notifications to your React Native Expo app:</Text>
            <Text style={styles.mb}>These instructions show how to add Native Notify push notifications to your React Native Expo app. After you follow these instructions, you will be able to send mass push notifications to all of your app users.</Text>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the setup guide below:</Text>
                    <iframe width="400" height="220" style={{ maxWidth: '100%' }} src="https://www.youtube.com/embed/JO850zWNwoY?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>

                </View>
                : null
            }

            <View style={styles.dottedLine}></View>
            <Text style={styles.HeadingText}>Expo Setup Guide:</Text>
            <Text style={styles.plainText}>You need Expo installed in your React Native app in order for Native Notify push notifications to work. Native Notify push notifications work in Expo "managed workflow" and/or "bare workflow". You do NOT have to eject out of Expo to use Native Notify push notifications.</Text>
            <Text style={styles.boldText}>If you already have Expo set up in your React Native app, you can skip this 'Expo Setup Guide' and go to Step 1 below this section.</Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.mb}>
                If you have not yet added Expo to your existing React Native app, visit this link to learn how to get Expo set up in your existing React Native app: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://docs.expo.dev/bare/installing-expo-modules')}>https://docs.expo.dev/bare/installing-expo-modules</TouchableOpacity>
            </Text>
            <View style={styles.dottedLine}></View>

            <View style={styles.flexRow}>
                <View style={styles.leftCont}>
                    <Text style={styles.boldTextTop}>Step 1:</Text>
                    <Text style={styles.plainText}>Run these commands in your Expo project terminal:</Text>
                    <Text style={styles.blackBacktext}>
                        <Text style={styles.mbSmall}>npm install native-notify</Text>
                        <Text>npx expo install expo-device expo-notifications expo-constants</Text>
                    </Text>


                    <Text style={styles.boldText}>Step 2:</Text>
                    <Text style={styles.plainText}>In your App.js or index.js file (for Expo 50+), include this import at the top of the screen:</Text>
                    <Text style={styles.blackBacktext}>
                        <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>registerNNPushToken</Text> <Text style={styles.purpleText}>from</Text> 'native-notify';</Text>
                    </Text>

                    <Text style={styles.boldText}>Step 3:</Text>
                    <Text style={styles.plainText}>Make sure your App.js or index.js file (for Expo 50+) is a Hook function. Here is an example:</Text>
                    <Text style={styles.blackBacktext}>
                        <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>App/Page</Text>() {"{"}</Text>
                        <Text>     ...</Text>
                        <Text>{"}"}</Text>
                    </Text>
                    <Text style={styles.plainText}>
                        This link explains Hooks in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-intro.html')}>https://reactjs.org/docs/hooks-intro.html</TouchableOpacity>
                    </Text>

                    <Text style={styles.boldText}>Step 4:</Text>
                    <Text style={styles.plainText}>Inside of your App function, at the top of your App function, paste this code:</Text>
                    <Text style={styles.blackBacktext}>
                        <Text><Text style={styles.yellowText}>registerNNPushToken</Text>({appInfo.app_id}, '{appInfo.app_token}');</Text>
                    </Text>
                    <Text style={styles.plainText}>Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. </Text>

                    <View style={styles.dottedLine}></View>

                    <TouchableOpacity onPress={() => setShowClassInstructions(!showClassInstructions)}>
                        <Text style={styles.plainText}>Click here to learn how to use 'native-notify' in an App.js Class Component if you would prefer {showClassInstructions ? '↑' : '↓'}</Text>
                    </TouchableOpacity>

                    {showClassInstructions
                        ? <View style={styles.purpleBack}>
                            <Text style={styles.mb}>If your App.js file is a Class Component, you can either convert your Class Component into a Hook function or wrap your App.js Class Component inside of a function to use the 'native-notify' package.</Text>

                            <Text style={styles.mb}>
                                This link shows how to wrap a Class Component inside of a function with the 'native-notify' package included: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://github.com/NativeNotify/wrap-class-in-hook')}>https://github.com/NativeNotify/wrap-class-in-hook</TouchableOpacity>
                            </Text>
                            <Text style={styles.plainText}>
                                This is a video that walks you through how to wrap an App.js Class Component inside of a function with the 'native-notify' package included:
                            </Text>
                            <iframe width="400" height="220" style={{ maxWidth: '100%' }} src="https://www.youtube.com/embed/hM7eXxd0Ohs?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>
                        </View>
                        : null
                    }
                </View>

                <View style={styles.rightCont}>
                    {screen.width < 900 ? <View style={styles.dottedLine}></View> : null}

                    <View style={styles.stickyRight}>
                        <Text style={styles.boldTextTop}>Here is an example of an App.js or index.js file (for Expo 50+) with Native Notify code included:</Text>
                        <Text style={styles.blackBacktext}>
                            <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>React, {"{ useState, useEffect }"} </Text><Text style={styles.purpleText}>from</Text> 'react';</Text>
                            <Text style={styles.mb}><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>registerNNPushToken</Text> <Text style={styles.purpleText}>from</Text> 'native-notify';</Text>

                            <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>App/Page</Text>() {"{"}</Text>
                            <Text style={styles.mb}>     <Text style={styles.yellowText}>registerNNPushToken</Text>({appInfo.app_id}, '{appInfo.app_token}');</Text>

                            <Text>     <Text style={styles.purpleText}>return</Text> (</Text>
                            <Text style={styles.tab2}>...</Text>
                            <Text>     )</Text>
                            <Text>{"}"}</Text>
                        </Text>
                    </View>
                </View>
            </View>

            <View style={styles.dottedLine}></View>
            <Text style={styles.boldTextTop}>Step 5:</Text>
            <Text style={styles.plainText}>If you do NOT have the Expo eas-cli package intalled on your computer, run the following command in your terminal:</Text>
            <Text style={styles.blackBacktext}>
                <Text>npm install -g eas-cli</Text>
            </Text>

            <Text style={styles.boldText}>Step 6:</Text>
            <Text style={styles.plainText}>If you have NOT logged into your eas account, run the following command in your terminal and follow the instructions:</Text>
            <Text style={styles.blackBacktext}>
                <Text>eas login</Text>
            </Text>

            <Text style={styles.boldText}>Step 7:</Text>
            <Text style={styles.plainText}>Run the following command in your terminal and follow the instructions to ensure your project has a projectId (your app requires an eas projectId for push notifications to work):</Text>
            <Text style={styles.blackBacktext}>
                <Text>eas init</Text>
            </Text>

            <Text style={styles.boldText}>Step 8:</Text>
            <Text style={styles.plainText}>Run the following command in your terminal and follow the instructions to open your app in the Expo Go app:</Text>
            <Text style={styles.blackBacktext}>
                <Text>npx expo start</Text>
            </Text>

            <Text style={styles.boldText}>Step 9:</Text>
            <Text style={styles.mb}>Open your app in the Expo Go app on your iOS phone or Android phone. If you have not downloaded the Expo Go app yet, here is the link:</Text>
            <Text style={styles.plainText}>
                <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://expo.dev/client')}>https://expo.dev/client</TouchableOpacity>
            </Text>
            <Text style={styles.boldText}>You MUST open your app on an iOS phone or Android phone. Push notifications do NOT work in an Android emulator or an iOS simulator.</Text>

            <View style={styles.dottedLine}></View>

            <Text style={styles.boldTextTop}>Step 10: </Text>
            <Text style={styles.HeadingText}>Send push notification using this form:</Text>

            <Text style={styles.mb}></Text>

            <View style={styles.sendCont}>
                <View style={styles.inputCont}>
                    <Text style={styles.text}>Notification Title:</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setTitle(text)}
                        placeholder={"Type Notification Title here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={title}
                    />
                </View>
                <View style={styles.inputCont}>
                    <Text style={styles.text}>Notification Message:</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setBody(text)}
                        placeholder={"Type Notification Message here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={body}
                        multiline={true}
                    />
                </View>

                <TouchableOpacity style={styles.sendButton} onPress={() => sendNotification()}>
                    <Text style={styles.buttonText}>Send Push Notification</Text>
                </TouchableOpacity>
            </View>
            <Text style={styles.plainText}>(You can manually send push notifications in the future using the form in the Send Notifications page by clicking the <TouchableOpacity onPress={() => goToSend()}>
                <Icon
                    name="send"
                    size={15}
                    color={'#000'}
                    onPress={() => goToSend()}
                />
            </TouchableOpacity> button at the top left of this page.)</Text>

            <View style={styles.dottedLine}></View>

            <Text style={styles.HeadingText}>You can also send push notifications using our API:</Text>
            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.plainText}>Post to this URL:</Text>
            <Text style={styles.blackBacktext}>
                <Text>https://app.nativenotify.com/api/notification</Text>
            </Text>

            <Text style={styles.boldText}>Step 2:</Text>
            <Text style={styles.plainText}>Use this post body (replacing the 'dateSent' with your current date):</Text>
            <Text style={styles.blackBacktext}>
                <Text> {`{`}</Text>
                <Text>      appId: {appInfo.app_id},</Text>
                <Text>      appToken: "{appInfo.app_token}",</Text>
                <Text>      title: "Push title here as a string",</Text>
                <Text>      body: "Push message here as a string",</Text>
                <Text>      dateSent: "{(date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear() + " " + hour + ":" + ('0' + date.getMinutes()).slice(-2) + amPM}"</Text>
                <Text> {" }"}</Text>
            </Text>
            <Text style={styles.plainText}>Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. </Text>

            <Text style={styles.boldText}>If you are using a non-javascript based server language like Python, make sure to specify that this is an 'application/json' post type in your post header.</Text>

            <View style={styles.dottedLine}></View>

        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        width: 'calc(100% - 60px)',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 200,
    },
    flexRow: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    leftCont: {
        width: screen.width > 900 ? '48%' : '100%',
    },
    rightCont: {
        width: screen.width > 900 ? '48%' : '100%',
        backgroundColor: screen.width > 900 ? '#3675d420' : '#fff',
        padding: screen.width > 900 ? 15 : 0,
        borderRadius: screen.width > 900 ? 6 : 0
    },
    stickyRight: {
        position: screen.width > 900 ? 'sticky' : 'relative',
        top: screen.width > 900 ? 10 : null
    },
    purpleBack: {
        backgroundColor: '#3675d420',
        padding: screen.width > 900 ? 15 : 10
    },

    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    button: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        // marginTop: 70,
        marginBottom: 10
    },
    HeadingText2: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        marginTop: 70,
        marginBottom: 10
    },
    h2: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 10
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    boldTextTop: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        // marginTop: 20,
        marginBottom: 5
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    medBlueText: {
        color: "rgb(81, 130, 204)"
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 30,
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5,
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 20
    },
    mbSmall: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    greentab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50,
        color: 'rgb(81, 167, 81)'
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    },
    greenText: {
        color: 'rgb(81, 167, 81)'
    },
    sendCont: {
        padding: 20,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 10
    },
    sendButton: {
        width: 220,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 5,
        // marginBottom: 70,
    },
    inputCont: {
        marginBottom: 20,
        width: '100%',
    },
    inputLarge: {
        width: '100%',
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    }
});