import React, { useState, useEffect, useRef } from 'react';
import { ActivityIndicator, View, ScrollView, StyleSheet, Text, Image, TouchableOpacity } from 'react-native';
import { useScrollToTop } from '@react-navigation/native';
import axios from 'axios';

import Icon from 'react-native-vector-icons/MaterialIcons';
import { FaArrowRightLong } from "react-icons/fa6";

import Header from '../../Headers/LoggedInHeader';

import ChooseAFramework from '../ChooseAFramework/ChooseAFramework';

export default function Dashboard({ navigation, host, userId, setUserId, userInfo, setUserInfo, setAppInfo, appUpdated, setAppUpdated }) {
    const [showLoading, setShowLoading] = useState(true);
    const [apps, setApps] = useState([]);
    const [showProScreen, setShowProScreen] = useState(false);
    const [email, setEmail] = useState("true");

    const ref = useRef(null);
    useScrollToTop(ref);

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        if (getCookie('logged_in') == 'true') {
            setUserId(getCookie('userId'));
            axios
                .get(`${host}/api/all/apps/${getCookie('userId')}`)
                .then(res => {
                    // console.log("all apps: ", res.data)
                    setApps(res.data);
                    // console.log("res.data: ", res.data)

                    axios
                        .get(`${host}/api/user/${getCookie('userId')}`)
                        .then(response => {
                            const data = response.data;
                            setUserInfo(response.data);
                            // console.log(data);

                            setEmail(response.data.email);

                            if (!data.paid_sub) {
                                // check session to see if payment was successful
                                checkSession(data)
                            } else if (data.paid_sub && !data.custom_pricing) {
                                axios
                                    .put(`${host}/api/stripe/subscription/quantity`, { userId: data.user_id, pushSubCount: data.push_sub_count })
                                // .then(responseToPost => console.log(responseToPost))
                                // .catch(errorOfPost => console.log(errorOfPost))
                                setShowLoading(false);
                            } else if (data.paid_sub && data.custom_pricing) {
                                setShowLoading(false);
                            }
                        })
                        .catch(err => console.log(err))
                })
                .catch(err => {
                    console.log(err);
                    setShowLoading(false);
                })
        } else {
            return navigation.navigate('Home');
        }
    }, [appUpdated, userId]);

    
    const openExpoApp = (appInfo) => {
        // console.log("openExpoApp appInfo: ", appInfo)
        setAppInfo(appInfo);
        document.cookie = `app_id = ${appInfo.app_id}`;
        setAppUpdated(appInfo.app_id);
        if (getCookie('app_id') == appInfo.app_id) {
            navigation.navigate('InApp');
        }
    }

    // const createApp = () => {
    //     return navigation.navigate('ChooseAFramework');
    // }
    
    // // website to app & expo
    // const openWebsiteToApp = (appInfo) => {
    //     setAppInfo(appInfo);
    //     document.cookie = `app_id = ${appInfo.app_id}`;
    //     setAppUpdated(appInfo.app_id);
    //     if (getCookie('app_id') == appInfo.app_id) {
    //         navigation.navigate('InAppWebsiteToApp');
    //     }
    // }

    // // flutter
    // const openFlutterApp = (appInfo) => {
    //     setAppInfo(appInfo);
    //     document.cookie = `flutter_app_id = ${appInfo.flutter_app_id}`;
    //     setAppUpdated(appInfo.flutter_app_id);
    //     if (getCookie('flutter_app_id') == appInfo.flutter_app_id) {
    //         navigation.navigate('InAppFlutter');
    //     }
    // }

    const handleStripeSubCreation = () => {
        setShowLoading(true);

        // Constants
        const BASE_PRICE = 99;
        const EXTRA_BLOCK_PRICE = 50;
        const SUBSCRIBER_THRESHOLD = 10000;
    
        // Calculate the new subscription price
        let newPrice = BASE_PRICE;
    
        if (userInfo.push_sub_count > SUBSCRIBER_THRESHOLD) {
            const extraBlocks = Math.ceil((userInfo.push_sub_count - SUBSCRIBER_THRESHOLD) / SUBSCRIBER_THRESHOLD);
            newPrice += extraBlocks * EXTRA_BLOCK_PRICE;
        }

        axios
            .post(`${host}/api/stripe/subscribe`, { userId, pushSubCount: userInfo.push_sub_count, price: newPrice })
            .then((res) => {
                if (res.data.url) {
                    window.location = res.data.url;
                } else {
                    setShowProScreen(false);
                    setShowLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                alert("Our server is experiencing high traffic or is in maintenance. Please try again later.");
                setShowLoading(false);
            });
    };


    const checkSession = (data) => {
        axios
            .post(`${host}/api/stripe/check/session`, {
                userId: getCookie('userId'),
                pushSubCount: userInfo.push_sub_count,
                appCount: userInfo.number_of_apps_in_account
            })
            .then(res => {
                // console.log(res.data);
                if (res.data === "fail") {
                    let today = new Date();
                    let priorDate = new Date().setDate(today.getDate() - 7);

                    if (+new Date(priorDate) > +new Date(data.date_signed_up) && data.push_sub_count > 10 && !data.custom_pricing) {
                        setShowProScreen(true);
                        setShowLoading(false);
                    }
                    setShowLoading(false);
                } else {
                    setShowProScreen(false);
                    setShowLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                setShowLoading(false);
            })
    }

    if (showLoading) {
        return (
            <View style={styles.page}>
                <View style={styles.centerScreen}>
                    <ActivityIndicator size="large" color="rgb(54, 117, 212)" />
                </View>
            </View>
        )
    }

    return (
        <View style={styles.page}>
            <Header 
                navigation={navigation} 
                setUserId={setUserId} 
                setAppInfo={setAppInfo} 
                userInfo={userInfo}
            />
            {/* update the showProScreen to be based on sub count */}
            {showProScreen
                ? <ScrollView
                    ref={ref}
                    style={styles.scrollViewContPro}
                    contentContainerStyle={styles.scrollView}
                >
                    <View style={styles.container}>
                        <View style={styles.goProCont}>
                            <Image
                                style={styles.logo}
                                source={require('../../assets/images/logo.png')}
                                resizeMode='cover'
                            />
                            <Text style={styles.h1}>Your free trial has ended.</Text>
                            <Text style={styles.subHeader}>Your 7-day free trial has ended and you have more than 10 push notification subscribers. You must upgrade your plan to Native Notify Premium to continue using Native Notify.</Text>
                            <button
                                onClick={() => handleStripeSubCreation()}
                                style={styles.goPremiumButton}
                            >Upgrade to Premium</button>
                            <Text style={styles.pSmall}>No commitments. Cancel anytime.</Text>
                        </View>

                        <Text style={styles.mb}></Text>

                        <View style={styles.pricingContainer}>
                            <Text style={styles.h1}>Pricing Details</Text>
                            <View style={styles.pricingTable}>
                                <View style={styles.pricingRow}>
                                    <Text style={[styles.pricingCell, styles.pricingHeader]}>Push Notification Subscribers</Text>
                                    <Text style={[styles.pricingCell, styles.pricingHeader]}>Price per Month</Text>
                                </View>
                                <View style={styles.pricingRow}>
                                    <Text style={styles.pricingCell}>0 - 10,000</Text>
                                    <Text style={styles.pricingCell}>$99</Text>
                                </View>
                                <View style={styles.pricingRow}>
                                    <Text style={styles.pricingCell}>Every additional 10,000</Text>
                                    <Text style={styles.pricingCell}>+$50</Text>
                                </View>
                            </View>

                            <Text style={styles.h1}>What's Included</Text>
                            <View style={styles.includedFeatures}>
                                {[
                                    "Save 1,000+ hours of development time with Native Notify's push notification infrastructure rather than building your own.",
                                    'Send and receive unlimited push notifications.',
                                    'Mass Push Notifications for sending push notifications to all users at once.',
                                    'Indie Push Notifications for sending push notifications to individuals.',
                                    'Notification Inbox for Mass Push Notifications.',
                                    'Indie Notification Inbox for Indie Push Notifications.',
                                    'Topic Groups to group subscribers by topic and send topic-based notifications.',
                                    'Follow Push Notifications to allow users to follow each other and send follower-specific notifications.',
                                    'Expedited customer support.',
                                ].map((feature, index) => (
                                    <View key={index} style={styles.featureRow}>
                                        <Icon name="check-circle-outline" size={24} color="green" style={styles.checkIcon} />
                                        <Text style={styles.featureItem}>{feature}</Text>
                                    </View>
                                ))}
                            </View>
                        </View>


                        <View style={styles.maxWidth}>
                            <Text style={styles.h1}>Frequently Asked Questions</Text>

                            <View style={styles.faqContainer}>
                                <View style={styles.faqItem}>
                                    <Text style={styles.faqQuestion}>What is my current cost?</Text>
                                    <Text style={styles.faqAnswer}>You currently have {userInfo.push_sub_count} push notification subscribers. So your current monthly cost is ${userInfo.push_sub_count <= 10000
                                        ? '99'
                                        : `${99 + 50 * Math.ceil((userInfo.push_sub_count - 10000) / 10000)}`}.</Text>
                                </View>

                                <View style={styles.faqItem}>
                                    <Text style={styles.faqQuestion}>Can I cancel anytime?</Text>
                                    <Text style={styles.faqAnswer}>Yes. There are no contracts. You can cancel anytime.</Text>
                                </View>

                                <View style={styles.faqItem}>
                                    <Text style={styles.faqQuestion}>Do you accept foreign currency?</Text>
                                    <Text style={styles.faqAnswer}>Yes. You can pay using a credit/debit card from your home country. We will convert your currency into USD for you.</Text>
                                </View>

                                <View style={styles.faqItem}>
                                    <Text style={styles.faqQuestion}>What is considered a 'push notification subscriber'?</Text>
                                    <Text style={styles.faqAnswer}>The following is considered a push notification subscriber:</Text>
                                    <Text style={styles.faqAnswer}>• A unique device that has successfully received one of your push notifications.</Text>
                                    <Text style={styles.faqAnswer}>• An Indie Push Notification subscriber.</Text>
                                    <Text style={styles.faqAnswer}>• A Follow Push Notification subscriber.</Text>
                                    <Text style={styles.faqAnswer}>• A Topic Group subscriber.</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </ScrollView>
                : <ScrollView
                    ref={ref}
                    style={styles.scrollViewCont}
                    contentContainerStyle={styles.scrollView}
                >
                    <ChooseAFramework
                        navigation={navigation}
                        host={host}
                        userId={userId}
                        setUserId={setUserId}
                        userInfo={userInfo}
                        setUserInfo={setUserInfo}
                        setAppInfo={setAppInfo}
                        appUpdated={appUpdated}
                        setAppUpdated={setAppUpdated}
                    />
                    <View style={styles.container}>
                        <View className style={styles.flexRow}>
                            <Text style={styles.pBold}>Your apps:</Text>
                            {apps
                                ? apps.map((e, i) => {
                                    return (
                                        <TouchableOpacity key={i} style={styles.appCont} onPress={() => openExpoApp(e)}>
                                            <Text style={styles.appTitle}>{e.app_name}</Text>
                                            <FaArrowRightLong />
                                        </TouchableOpacity>
                                    )
                                    
                                    // if ((!e.framework || e.framework === 'expo') && !e.is_a_website_to_app) {
                                    //     return (
                                    //         <TouchableOpacity key={i} style={styles.appCont} onPress={() => openExpoApp(e)}>
                                    //             <Text style={styles.appTitle}>{e.app_name}</Text>
                                    //             <FaArrowRightLong />
                                    //         </TouchableOpacity>
                                    //     )
                                    // } else if ((!e.framework || e.framework === 'expo') && e.is_a_website_to_app) {
                                    //     return (
                                    //         <TouchableOpacity key={i} style={styles.appCont} onPress={() => openWebsiteToApp(e)}>
                                    //             <Text style={styles.appTitle}>{e.app_name}</Text>
                                    //             <FaArrowRightLong />
                                    //         </TouchableOpacity>
                                    //     )
                                    // }

                                    // if (e.framework === 'flutter') {
                                    //     return (
                                    //         <TouchableOpacity key={i} style={styles.appCont} onPress={() => openFlutterApp(e)}>
                                    //             <Text style={styles.appTitle}>{e.flutter_app_name}</Text>
                                    //             <FaArrowRightLong />
                                    //         </TouchableOpacity>
                                    //     )
                                    // }
                                })
                                : null
                            }
                        </View>
                    </View>
                </ScrollView>
            }

        </View>
    )
}

let flexRowJustify, appMR, appWH;

if (screen.width < 500) {
    flexRowJustify = 'space-between';
    appMR = 0;
} else {
    flexRowJustify = 'flex-start';
    appMR = 30;
}

if (screen.width > 500) {
    appWH = '20vw';
} else { appWH = '41vw' }

const styles = StyleSheet.create({
    page: {
        flex: 1,
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        minWidth: '100vw',
        backgroundColor: "white",
        alignItems: 'center',
    },
    scrollViewCont: {
        height: '100%',
        width: '100%',
    },
    scrollViewContPro: {
        height: '100%',
        width: '100%',
        backgroundColor: "#47689915",
    },
    scrollView: {
        height: '100%',
        width: '100%',
        // padding: '5%',
        // paddingTop: 30,
    },
    container: {
        width: '100%',
        alignItems: 'center',
        paddingBottom: 100,
        paddingHorizontal: '5%',
    },
    flexRow: {
        width: '100%',
        maxWidth: 1200,
        gap: 20
    },
    maxWidth: {
        width: '100%',
        maxWidth: 1200,
    },
    centerScreen: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },


    goProCont: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 15,
        paddingTop: 30,
        paddingBottom: 30,
        marginBottom: 15,
        textAlign: 'center',
        backgroundColor: 'white',
        boxShadow: '0px 1px 3px #6b6b6b',
        borderRadius: '6px',
        maxWidth: '90vw',
        marginTop: 30,
        width: '100%',
        maxWidth: 1200
    },

    h1: {
        fontFamily: 'Arial',
        fontWeight: 700,
        fontSize: 24,
        marginBottom: 10,
        // textAlign: 'center'
    },
    subHeader: {
        fontFamily: 'Arial',
        fontWeight: 500,
        color: "#6b6b6b",
        fontSize: 18,
        marginBottom: 10,
        maxWidth: 800
    },
    p: {
        fontSize: 18,
        fontFamily: 'Arial',
        fontWeight: 400,
        marginBottom: 5,
    },
    pSmall: {
        fontSize: 12,
        fontFamily: 'Arial',
        fontWeight: 400,
        color: "#6b6b6b",
    },
    pBold: {
        fontSize: 18,
        fontFamily: 'Arial',
        fontWeight: 700,
        marginTop: 20
    },

    logo: {
        width: 60,
        height: 60
    },
    goPremiumButton: {
        width: 'fit-content',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 20,
        marginBottom: 10,
        backgroundColor: '#2690cb',
        color: 'white',
        border: '0',
        borderRadius: '6px',
        boxShadow: '0px 1px 2px #26272b',
        fontSize: '20px',
        fontWeight: '700',
        cursor: 'pointer'
    },
    createNotificationButton: {
        width: 220,
        backgroundColor: '#e54b4d',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 5,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 1px 2px #26272b',
        marginBottom: 30
    },
    createNotificationText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 20,
        color: '#fff',
        marginLeft: 10,
        marginRight: 3
    },
    openButton: {
        paddingHorizontal: 15,
        paddingVertical: 5,
        borderWidth: 1,
        borderColor: '#d6d6d6',
        borderRadius: 3,
        backgroundColor: '#3568b3',
        color: 'white',
        fontWeight: 700
    },
    icon: {
        paddingTop: 2,
        marginLeft: -1
    },

    appCont: {
        height: 60,
        width: '100%',
        borderWidth: 1,
        borderColor: '#d6d6d6',
        borderRadius: 3,
        // boxShadow: '0 0 3px 0 #d6d6d6',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        backgroundColor: '#fcfcfc',
    },
    appTitle: {
        fontFamily: 'Arial',
        fontWeight: 600,
        padding: 15,
        borderRadius: 6,
    },
    mb: {
        marginBottom: 60
    },

    pricingContainer: {
        width: '100%',
        maxWidth: 1200,
        marginTop: 30,
        marginBottom: 30,
        alignItems: 'center',
    },
    pricingTable: {
        width: '100%',
        borderWidth: 1,
        borderColor: '#e0e0e0',
        borderRadius: 5,
        overflow: 'hidden',
        marginBottom: 30,
        backgroundColor: 'white',
    },
    pricingRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#e0e0e0',
    },
    pricingCell: {
        flex: 1,
        padding: 15,
        justifyContent: 'center',
        alignItems: 'center',
    },
    pricingHeader: {
        backgroundColor: '#f5f5f5',
        fontWeight: 'bold',
        fontSize: 18,
    },
    includedFeatures: {
        width: '100%',
        backgroundColor: "white",
        borderWidth: 1,
        borderColor: '#e0e0e0',
        borderRadius: 5,
    },
    featureRow: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: '#e0e0e0',
    },
    checkIcon: {
        marginRight: 15,
    },
    featureItem: {
        flex: 1,
        fontSize: 18,
        fontFamily: 'Arial',
        lineHeight: 26,
        color: '#333',
    },
    faqContainer: {
        width: '100%',
        maxWidth: 800,
        marginTop: 20,
    },
    faqItem: {
        marginBottom: 20,
        paddingBottom: 20,
        borderBottomWidth: 1,
        borderBottomColor: '#e0e0e0',
    },
    faqQuestion: {
        fontSize: 20,
        fontFamily: 'Arial',
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#2690cb',
    },
    faqAnswer: {
        fontSize: 18,
        fontFamily: 'Arial',
        marginBottom: 5,
        lineHeight: 26,
        color: '#333',
    },

});