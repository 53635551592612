import React, { useState, useEffect, useRef } from 'react';
import { View, ScrollView, StyleSheet, TextInput, Text, TouchableOpacity, Platform, Button, Keyboard, Linking } from 'react-native';
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';

import Header from '../../Headers/LoggedInHeader';

export default function Settings({ navigation, host, userId, setUserId, userInfo, setUserInfo, appInfo, setAppInfo, appUpdated }) {
    const [oldEmail, setOldEmail] = useState('');
    const [newEmail, setNewEmail] = useState('');

    function getCookie(name) {
        function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
        return match ? match[1] : null;
    }

    useEffect(() => {
        if(getCookie('logged_in') == 'true') {
            setUserId(getCookie('userId'));
            axios
                .get(`${host}/api/user/${getCookie('userId')}`)
                .then(res => {
                    // console.log(res.data, userInfo);
                    setUserInfo(res.data);
                    setOldEmail(res.data.email);
                })
                .catch(err => console.log(err))
        } else {
            return navigation.navigate('Home');
        }
    }, [appUpdated]);

    // update email
    // update password
    // update credit card info

    const onToken = (token) => {
        console.log(token)
        token.card = void 0;

        axios
          .post(`${host}/api/stripe/update/card`, { token, email: userInfo.email})
          .then(res => {
            //   console.log(res);
    
              if(Platform.OS !== "web") {
                Alert.alert("", "You have successfully updated your credit card info!");
                Keyboard.dismiss();
              } else {
                alert("You have successfully updated your credit card info!");
                Keyboard.dismiss();
              }
          })
          .catch()
    }

    const handleDeleteUser = () => {
        console.log(getCookie('userId'));
        Keyboard.dismiss();
    
        if(confirm("Are you sure you want to delete your account? Your service will immediately stop working.")) {
            axios
                .delete(`${host}/api/delete/user/${getCookie('userId')}`)
                .then(res => {
                    // console.log(res);
                    alert("You have successfully deleted your account. We will save your login info and app data in case you want to become a Native Notify member again. Just come back to NativeNotify.com, login, and all of your data will be waiting for you.");
                    var d = new Date();
                    d.setDate(d.getDate() - 1);
                    document.cookie = `logged_in = false; expires=${d};`;
                    document.cookie = `logged_in = false; domain=nativenotify.com; expires=${d};`;
                    document.cookie = `logged_in = false; domain=app.nativenotify.com; expires=${d};`;
                    document.cookie = `userId = 0; expires=${d};`;
                    document.cookie = `userId = 0; domain=nativenotify.com; expires=${d};`;
                    document.cookie = `userId = 0; domain=app.nativenotify.com; expires=${d};`;
                    document.cookie = `app_id = 0; expires=${d};`;
                    document.cookie = `app_id = 0; domain=nativenotify.com; expires=${d};`;
                    document.cookie = `app_id = 0; domain=app.nativenotify.com; expires=${d};`;
                    setUserId(0);
                    navigation.navigate('Home');
                })
                .catch(err => console.log(err))
        } 
    }

    const changeEmail = () => {
        if(!newEmail.includes('@') || !newEmail.includes('.')) {
            return alert('Type a real email address before changing your email address.');
        }

        if(confirm("Are you sure you want to change your email?")) {
            axios
                .put(`${host}/api/update/email`, { oldEmail, newEmail })
                .then(res => {
                    setOldEmail(res.data[0].email);
                    setNewEmail('');
                })
                .catch(() => {
                    alert("The new email is already in use with another account. Please try to update your email with a different email not currently in use with another Native Notify account.");
                    setNewEmail('');
                })
        }
        
    }

    return (
        <ScrollView keyboardShouldPersistTaps={'handled'} style={styles.page} contentContainerStyle={styles.pageCont}>
            <Header 
                navigation={navigation} 
                setUserId={setUserId} 
                setAppInfo={setAppInfo} 
                userInfo={userInfo}
            />

            <View style={{ width: "90%", marginTop: 45 }}>
                <Text style={styles.textH1}>Settings</Text>
            </View>

            <View style={styles.body}>
                <View style={styles.container}>
                    <Text style={styles.textBoldLarge}>Update account email:</Text>
                    <Text style={styles.text}>Current email: {oldEmail}</Text>
                    <TextInput 
                        style={styles.inputLarge}
                        onChangeText={(text) => setNewEmail(text)}
                        placeholder={"Type new email here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={newEmail}
                    />
                    <TouchableOpacity style={styles.redButton} onPress={() => changeEmail(newEmail)}>
                        <Text style={styles.buttonText}>Change your email</Text>
                    </TouchableOpacity>
                </View>

                {/* <View style={styles.line}></View> */}

                <View style={styles.container}>
                    <Text style={styles.textBoldLarge}>Update account password:</Text>
                    <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('ResetPasswordOne')}>
                        <Text style={styles.buttonText}>Change your password</Text>
                    </TouchableOpacity>
                </View>

                {userInfo.paid_sub
                    ? <View style={{ width: "100%" }}>
                        <View style={styles.container}>
                            <Text style={styles.textBoldLarge}>View/update billing information & download invoices:</Text>
                            <TouchableOpacity style={styles.button} onPress={() => Linking.openURL('https://billing.stripe.com/p/login/aEU16ag2B8l04NicMM')}>
                                <Text style={styles.buttonText}>Visit Billing Portal</Text>
                            </TouchableOpacity>
                        </View>

                        <View style={styles.container}>
                            <Text style={styles.textBoldLarge}>Usage information:</Text>
                            {userInfo.push_sub_count > 0
                                ? <Text style={styles.text}>Push Notification Subscriber Count: {userInfo.push_sub_count}</Text>
                                : null
                            }

                            {userInfo.push_sub_count > 20 && !userInfo.custom_pricing
                                ? <Text style={styles.text2}>
                                    Your monthly Native Notify subscription fee: $
                                    {userInfo.push_sub_count / 10000 < 1 
                                        ? '99' 
                                        : `${(Math.floor(userInfo.push_sub_count / 10000) * 50) + 99}`
                                    }/month.
                                </Text>
                                : null
                            }
                        </View>

                        <View style={styles.contDanger}>
                            <Text style={styles.textRed}>Danger Zone!</Text>
                            <Text style={styles.text}>If you downgrade your account to the Free plan, your push notifications will stop working if you have more than 10 push notification subscribers. If you do downgrade to the Free plan, we will save your data so you can restart your Premium plan at any time.</Text>
                            <View style={styles.redButton2}>
                                <Button 
                                    title="Downgrade to Free Plan"
                                    color={"#ff0000"}
                                    onPress={() => handleDeleteUser()}
                                />
                            </View>
                        </View>
                    </View>
                    : null
                }

                
            </View>
        </ScrollView>
        
    )
}

const styles = StyleSheet.create({
    page: {
        flex: 1, 
        height: '100%',
        minHeight: '100vh',
        width: '100%',
        minWidth: '100vw',
        backgroundColor: 'rgb(250, 250, 250)',
    },
    pageCont: {
        display: 'flex',
        alignItems: 'center'
    },
    body: {
        width: '90%',
        alignItems: 'flex-start',
        marginTop: 10,
        marginBottom: 100,
        borderWidth: 1,
        borderColor: '#bbb',
        borderRadius: 6,
        backgroundColor: "#fff",
        boxShadow: "0px 0px 3px rgb(214,214,214)"
    },
    container: {
        width: "100%",
        padding: 30,
        borderBottomWidth: 2,
        borderColor: '#dbdbdb',
    },
    line: {
        width: "100%",
        borderWidth: 1,
        borderColor: '#dbdbdb',
        marginTop: 30,
        marginBottom: 30,
    },

    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 18,
        color: '#fff',
    },
    button: {
        width: 'fit-content',
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingRight: 15,
        paddingLeft: 15,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    button2: {
        backgroundColor: 'rgb(54, 117, 212)',
        alignSelf: 'flex-start',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 20
    },
    redButton: {
        backgroundColor: '#ff0000',
        alignSelf: 'flex-start',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 20
    },
    redButton2: {
        backgroundColor: '#ff0000',
        alignSelf: 'flex-start',
        alignItems: 'center',
        justifyContent: 'center',
        paddingRight: 20,
        paddingLeft: 20,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    contDanger: {
      width: "100%",
      marginTop: 100,
    //   marginBottom: 50,
      padding: 30,
      textAlign: "left"
    },
    text: {
        fontSize: 18,
        fontFamily: "Arial",
        // marginTop: 60,
        marginBottom: 10
    },
    text2: {
        fontSize: 18,
        fontFamily: "Arial",
        // marginTop: 10
    },
    textBold:{
        fontWeight: 700,
    },
    textBoldLarge:{
        fontSize: 18,
        fontFamily: "Arial",
        fontWeight: 700,
        marginBottom: 20
    },
    textH1:{
        fontSize: 24,
        fontFamily: "Arial",
        fontWeight: 700,
        marginBottom: 10
    },
    textRed: {
      fontSize: 20,
      fontFamily: "Arial",
      fontWeight: 700,
      color: "#ff0000",
      marginBottom: 20,
    },
    inputLarge: {
        width: 300,
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        // marginBottom: 15
    }
});