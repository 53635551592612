import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Platform } from 'react-native';
import axios from 'axios';

import DocsMenu from './Components/DocsMenu';

import Icon from 'react-native-vector-icons/Ionicons';

export default function NotificationInboxDocs({ appInfo, InAppNavigation }) {
    const { setScreenName } = InAppNavigation;

    return (
        <View style={styles.body}>
            <DocsMenu InAppNavigation={InAppNavigation} />
            <Text style={styles.HeadingText}>Follow these steps to create In-App Notification Inbox:</Text>
            <Text style={styles.plainText}>These instructions show how to create a Notification Inbox within your app. Your users will be able to see a history of all the previous notifications sent.</Text>

            <Text style={styles.mb}></Text>
            <Text style={styles.mb}></Text>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the setup guide below:</Text>
                    <iframe width="400" height="220" src="https://www.youtube.com/embed/SXN4VdoK6U4?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>

                </View>
                : null
            }

            <View style={styles.dottedLine}></View>

            <Text style={styles.boldText}>Notification Inbox preview:</Text>
            <Text style={styles.plainText}>Here's how the Notification Inbox API works:</Text>
            <Text style={styles.mb}></Text>
            <Text style={styles.plainText}><Text style={styles.bT}>Get Notifications Sent:</Text> Use the native-notify 'getNotificationInbox' function to get a history of all the push notifications you have sent in the past.</Text>
            <Text style={styles.plainText}><Text style={styles.bT}>Use:</Text> Use the Notification Inbox data from the 'getNotificationInbox' function to create an In-App Notification Inbox.</Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>
            <Text style={styles.HeadingText}>Setup:</Text>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.blackBacktext}>
                <Text style={styles.mbSmall}>npm uninstall native-notify</Text>
                <Text style={styles.mbSmall}>npm install native-notify</Text>
                <Text>npx expo install expo-device expo-notifications expo-constants</Text>
            </Text>

            <Text style={styles.boldText}>Step 2:</Text>
            <Text style={styles.plainText}>In your Notification Inbox screen, make sure these imports are included at the top of the screen:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>React, {"{ useState, useEffect }"} </Text><Text style={styles.purpleText}>from</Text> 'react-native';</Text>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>{"{ getNotificationInbox }"} </Text><Text style={styles.purpleText}>from</Text> 'native-notify';</Text>
            </Text>

            <Text style={styles.boldText}>Step 3:</Text>
            <Text style={styles.plainText}>Make sure you are using a Hook function as your Notification Inbox function. Here is an example:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>NotificationInbox</Text>() {"{"}</Text>
                <Text>     ...</Text>
                <Text>{"}"}</Text>
            </Text>
            <Text style={styles.plainText}>
                This link explains Hooks in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-intro.html')}>https://reactjs.org/docs/hooks-intro.html</TouchableOpacity>
            </Text>

            <Text style={styles.boldText}>Step 4:</Text>
            <Text style={styles.plainText}>Inside of your Notification Inbox screen, create a useState to store your Notification Inbox data like this:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.blueText}>const</Text> [data, setData] = <Text style={styles.yellowText}>useState</Text>([]);</Text>
            </Text>

            <Text style={styles.boldText}>Step 5:</Text>
            <Text style={styles.plainText}>Paste this 'useEffect' function into your Notification Inbox function:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.yellowText}>useEffect</Text>(<Text style={styles.blueText}>async</Text> () => {"{"}</Text>
                <Text><Text style={styles.blueText}>    let</Text> <Text style={styles.lightBlueText}>notifications</Text> = <Text style={styles.purpleText}>await</Text> <Text style={styles.yellowText}>getNotificationInbox</Text>({appInfo.app_id}, '{appInfo.app_token}');</Text>
                <Text>    console.log(<Text style={styles.lightBlueText}>"notifications: ", notifications</Text>);</Text>
                <Text>    <Text style={styles.yellowText}>setData</Text>(notifications);</Text>
                <Text>{"}, []);"}</Text>
            </Text>
            <Text style={styles.mb}>
                This link explains how 'useEffect' works in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-effect.html')}>https://reactjs.org/docs/hooks-effect.html</TouchableOpacity>
            </Text>
            <Text style={styles.plainText}>Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. </Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>

            <Text style={styles.plainText}>Here is an example of a NotificationInbox.js screen with Native Notify Notification Inbox code included:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>React, {"{ useState, useEffect }"} </Text><Text style={styles.purpleText}>from</Text> 'react';</Text>
                <Text style={styles.mb}><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>{"{ getNotificationInbox }"}</Text> <Text style={styles.purpleText}>from</Text> 'native-notify';</Text>

                <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>NotificationInbox</Text>() {"{"}</Text>
                <Text style={styles.mb}><Text style={styles.blueText}>    const</Text> [data, setData] = <Text style={styles.yellowText}>useState</Text>([]);</Text>

                <Text>     <Text style={styles.yellowText}>useEffect</Text>(<Text style={styles.blueText}>async</Text> () => {"{"}</Text>
                <Text>          <Text style={styles.blueText}>let</Text> <Text style={styles.lightBlueText}>notifications</Text> = <Text style={styles.purpleText}>await</Text> <Text style={styles.yellowText}>getNotificationInbox</Text>({appInfo.app_id}, '{appInfo.app_token}');</Text>
                <Text>          console.log(<Text style={styles.lightBlueText}>"notifications: ", notifications</Text>);</Text>
                <Text>          <Text style={styles.yellowText}>setData</Text>(notifications);</Text>
                <Text style={styles.mb}>     {"}, []);"}</Text>

                <Text>     <Text style={styles.purpleText}>return</Text> (</Text>
                <Text style={styles.tab2}>...</Text>
                <Text>     )</Text>
                <Text>{"}"}</Text>
            </Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>

            <Text style={styles.boldText}>
                And that's it! You can now view the notifications that will appear in your Notification Inbox by clicking this icon at the left of your screen: {"    "}
            </Text>
            <Text style={styles.mb}></Text>
            <Icon
                name="mail"
                size={60}
                color={'#000'}
                onPress={() => setScreenName('notInbox')}
            />

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>

            <Text style={styles.HeadingText}>How to create an Unread Notifications Icon that shows the number of unread notifications in an inbox:</Text>
            <Text style={styles.plainText}>Use the functions below to get the number of unread notifications in the notification inbox.</Text>

            <Text style={styles.mb}></Text>
            <Text style={styles.mb}></Text>

            {Platform.OS === "web"
                ? <View style={styles.youTubeVideo}>
                    <Text style={styles.h2}>This video walks you through the setup guide below:</Text>
                    <iframe width="400" height="220" src="https://www.youtube.com/embed/Ed6EBYEK4OY?rel=0" title="Native Notify Demo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" allowfullscreen="allowfullscreen"></iframe>

                </View>
                : null
            }

            <View style={styles.dottedLine}></View>

            <Text style={styles.boldText}>Step 1:</Text>
            <Text style={styles.blackBacktext}>
                <Text style={styles.mbSmall}>npm uninstall native-notify</Text>
                <Text style={styles.mbSmall}>npm install native-notify</Text>
                <Text>npx expo install expo-device expo-notifications expo-constants</Text>
            </Text>

            <Text style={styles.boldText}>Step 2:</Text>
            <Text style={styles.plainText}>In your Footer or Header component (wherever your Unread Notifications Icon is located), make sure these imports are included at the top of the screen:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>React, {"{ useState, useEffect }"} </Text><Text style={styles.purpleText}>from</Text> 'react-native';</Text>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>{"{ getUnreadNotificationInboxCount }"} </Text><Text style={styles.purpleText}>from</Text> 'native-notify';</Text>
            </Text>

            <Text style={styles.boldText}>Step 3:</Text>
            <Text style={styles.plainText}>Make sure you are using a Hook function as your Footer or Header component (wherever your Unread Notifications Icon is located). Here is an example:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>Footer</Text>() {"{"}</Text>
                <Text>     ...</Text>
                <Text>{"}"}</Text>
            </Text>
            <Text style={styles.plainText}>
                This link explains Hooks in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-intro.html')}>https://reactjs.org/docs/hooks-intro.html</TouchableOpacity>
            </Text>

            <Text style={styles.boldText}>Step 4:</Text>
            <Text style={styles.plainText}>Inside of your Footer or Header component (wherever your Unread Notifications Icon is located), create a useState to store your Unread Notifications Count like this:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.blueText}>const</Text> [unreadNotificationCount, setUnreadNotificationCount] = <Text style={styles.yellowText}>useState</Text>(0);</Text>
            </Text>

            <Text style={styles.boldText}>Step 5:</Text>
            <Text style={styles.plainText}>Paste this 'useEffect' function into your Footer or Header component (wherever your Unread Notifications Icon is located):</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.yellowText}>useEffect</Text>(<Text style={styles.blueText}>async</Text> () => {"{"}</Text>
                <Text><Text style={styles.blueText}>    let</Text> <Text style={styles.lightBlueText}>unreadCount</Text> = <Text style={styles.purpleText}>await</Text> <Text style={styles.yellowText}>getUnreadNotificationInboxCount</Text>({appInfo.app_id}, '{appInfo.app_token}');</Text>
                <Text>    console.log(<Text style={styles.lightBlueText}>"unreadCount: ", unreadCount</Text>);</Text>
                <Text>    <Text style={styles.yellowText}>setUnreadNotificationCount</Text>(unreadCount);</Text>
                <Text>{"}, []);"}</Text>
            </Text>
            <Text style={styles.mb}>
                This link explains how 'useEffect' works in detail: <TouchableOpacity style={styles.blueText} onPress={() => window.open('https://reactjs.org/docs/hooks-effect.html')}>https://reactjs.org/docs/hooks-effect.html</TouchableOpacity>
            </Text>
            <Text style={styles.plainText}>Do NOT change the 'appId' or the 'appToken' value in this code. We put your appId and appToken in the code for you. </Text>

            <Text style={styles.boldText}>Step 6:</Text>
            <Text style={styles.plainText}>Use this unreadNotificationCount to create an unread notification bubble over your Unread Notifications Icon whenever the number of unreadNotificationCount is above zero (0).</Text>

            <View style={styles.dottedLine}></View>
            <Text style={styles.mb}></Text>

            <Text style={styles.plainText}>Here is an example of a Header.js screen with Unread Notification Count code included:</Text>
            <Text style={styles.blackBacktext}>
                <Text><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>React, {"{ useState, useEffect }"} </Text><Text style={styles.purpleText}>from</Text> 'react';</Text>
                <Text style={styles.mb}><Text style={styles.purpleText}>import</Text> <Text style={styles.lightBlueText}>{"{ getUnreadNotificationInboxCount }"}</Text> <Text style={styles.purpleText}>from</Text> 'native-notify';</Text>

                <Text><Text style={styles.purpleText}>export default</Text> <Text style={styles.medBlueText}>function</Text> <Text style={styles.yellowText}>Header</Text>() {"{"}</Text>
                <Text style={styles.mb}><Text style={styles.blueText}>    const</Text> [unreadNotificationCount, setUnreadNotificationCount] = <Text style={styles.yellowText}>useState</Text>(0);</Text>

                <Text>     <Text style={styles.yellowText}>useEffect</Text>(<Text style={styles.blueText}>async</Text> () => {"{"}</Text>
                <Text>          <Text style={styles.blueText}>let</Text> <Text style={styles.lightBlueText}>unreadCount</Text> = <Text style={styles.purpleText}>await</Text> <Text style={styles.yellowText}>getUnreadNotificationInboxCount</Text>({appInfo.app_id}, '{appInfo.app_token}');</Text>
                <Text>          console.log(<Text style={styles.lightBlueText}>"unreadCount: ", unreadCount</Text>);</Text>
                <Text>          <Text style={styles.yellowText}>setUnreadNotificationCount</Text>(unreadCount);</Text>
                <Text style={styles.mb}>     {"}, []);"}</Text>

                <Text>     <Text style={styles.purpleText}>return</Text> (</Text>
                <Text style={styles.tab2}>...</Text>
                <Text>     )</Text>
                <Text>{"}"}</Text>
            </Text>

            <View style={styles.dottedLine}></View>

            <Text style={styles.boldText}>An important note about Unread Notification Count:</Text>
            <Text style={styles.mb}>The Unread Notification Count is only accurate as long as the user does not update or reinstall your app. Every time the user updates to a newer version of your app or reinstalls your app, the Unread Notification Count resets to the total number of notifications in the Notification Inbox.</Text>
            <Text style={styles.mb}>The reason for this is that we track unread notifications based on the tokens created on the backend each time a user installs your app. Each time a user updates or reinstalls your app, a new unique token is created that we use to base the number of unread notifications on.</Text>

            <Text style={styles.boldText}>An Unread Notification Count Hack to (sort of) fix the inaccuracy issue:</Text>

            <Text style={styles.mb}>Don't show the number of unread notifications. Just put an empty unread notification bubble over the notification icon whenever the unreadNotificationCount is above 0 and DON'T show it whenever the count is 0. This may be the best option from a UX standpoint.</Text>
            <Text style={styles.mb}>Another idea is to only show the number of unread notifications in a notification count bubble over your icon if the number of unread notifications is under a certain amount, like 5 or 10. If the number of unread notifications is over your set amount, you could just show an empty unread notification bubble over your notification icon.</Text>

            <Text style={styles.boldText}>How to fix the Unread Notification Count inaccuracy issue:</Text>
            <Text style={styles.mb}>The only way to always have a completely accurate unread notifications count is to set up an Indie Notification Inbox. Click on the 'Indie Notification Inbox Setup' menu item at the top of this screen to learn how to setup an Indie Notification Inbox.</Text>
            <Text style={styles.mb}>Indie Notification Inboxes base the number of unread notifications off each individual user's Indie Push ID. If your user is logged in, you can use the Indie Push ID to always have an accurate unread notifications count accross all devices no matter how ofter your user updates or installs their app.</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    body: {
        width: '85%',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 200
    },

    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 14,
        color: '#fff',
    },
    button: {
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 700,
        fontSize: 25,
        // marginTop: 70,
        marginBottom: 10
    },
    h2: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 20,
        marginBottom: 10
    },
    boldText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 18,
        marginTop: 20,
        marginBottom: 5
    },
    bT: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 16,
        marginBottom: 5
    },
    blackBacktext: {
        backgroundColor: "#1b1f23",
        color: "#fff",
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        padding: 20,
        borderRadius: 6,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column"
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    medBlueText: {
        color: "rgb(81, 130, 204)"
    },
    dottedLine: {
        height: 1,
        width: "100%",
        borderTopWidth: 1,
        borderColor: "#000",
        borderStyle: "dotted",
        marginTop: 30,
        marginBottom: 10,
    },
    lightBlueText: {
        color: "rgb(144, 180, 218)"
    },
    plainText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    purpleText: {
        color: "rgb(187, 86, 187)"
    },
    mb: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 10
    },
    tab2: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginLeft: 50
    },
    yellowText: {
        color: "rgb(222, 222, 160)"
    },

    recCont: {
        width: '100%',
        backgroundColor: '#fff',
        marginTop: 10,
        marginBottom: 10,
        padding: 20,
        paddingBottom: 15,
        borderRadius: 6,
        flexWrap: 'wrap',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    recText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 14,
        marginBottom: 5,
    },
    recTextBold: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 5,
    },
    leftPart: {
        width: '50%'
    }
});