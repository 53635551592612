import React, { useState, useEffect, useRef } from 'react';
import { Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as Linking from 'expo-linking';

import Home from '../screens/Home/Home';
import Admin from '../screens/Admin/Admin';
import IntroDiscount from '../screens/IntroDiscount/IntroDiscount';
import Login from '../screens/LoginSignUpReset/Login/Login';
import SignUpOne from '../screens/LoginSignUpReset/SignUpOne/SignUpOne';
import SignUpTwo from '../screens/LoginSignUpReset/SignUpTwo/SignUpTwo';
import ResetPasswordOne from '../screens/LoginSignUpReset/ResetPasswordOne/ResetPasswordOne';

import Dashboard from '../screens/Dashboard/Dashboard';
import ChooseAFramework from '../screens/ChooseAFramework/ChooseAFramework';
import InApp from '../screens/InAppExpo/InAppNavigation';
import InAppFlutter from '../screens/InAppFlutter/InAppNavigation';
import InAppWebsiteToApp from '../screens/InAppWebsiteToApp/InAppNavigation';
import FAQ from '../screens/FAQ/FAQ';
import Settings from '../screens/Settings/Settings';
import Upgrade from '../screens/Upgrade/Upgrade';

const Stack = createStackNavigator();
const prefix = Linking.createURL('/');

import Svg, { Defs, RadialGradient, Stop, Rect } from 'react-native-svg';

const RadialGradientBackground = ({ style }) => (
    <Svg height="100%" width="100%" style={style}>
      <Defs>
        <RadialGradient
            id="radialGrad"
            cx="50%"    // Center horizontally
            cy="90%"    // Move center closer to the top
            r="80%"     // Horizontal radius covers full width
            fx="50%"    // Focal point aligns with center horizontally
            fy="160%"    // Focal point aligns with new center vertically
        >
            <Stop offset="0%" stopColor="rgb(53, 118, 212)" stopOpacity="1" />
            <Stop offset="100%" stopColor="rgb(20, 20, 60)" stopOpacity="1" />
        </RadialGradient>
      </Defs>
      <Rect x="0" y="0" width="100%" height="100%" fill="url(#radialGrad)" />
    </Svg>
  );

export default function AppNavigation(props) {
    const routeNameRef = useRef();
    const navigationRef = useRef();

    const { 
        host,
        userId, 
        setUserId,
        userInfo, 
        setUserInfo,
        validationCode, 
        setValidationCode,
        expoToken,

        appInfo,
        setAppInfo,
        appUpdated, 
        setAppUpdated        
    } = props;

    // idea here: just pass down props={props} to every screen and deconstruct on each screen
    // or figure out const AppState = props; and pass down AppState everywhere

    const linking = {
        // prefixes: ['https://apps.pagecow.com', 'exp://localhost:19006'],
        // prefixes: ['exp://localhost:19006'],
        prefixes: [prefix],
        config: {
            screens: {
                Home: '',
                Admin: 'admin',
                IntroDiscount: 'intro',
                Login: 'login',
                SignUpOne: 'sign-up-one',
                SignUpTwo: 'sign-up-two',
                ResetPasswordOne: 'reset-password',

                Dashboard: 'dashboard',
                Upgrade: 'upgrade',
                ChooseAFramework: 'choose-a-framework',
                InAppWebsiteToApp: 'in-app-website-to-app',
                InApp: 'in-app',
                InAppFlutter: 'in-app-flutter',
                FAQ: 'FAQ',
                Settings: 'settings',
                
                Example: {
                    path: 'example/:id/:section',
                    parse: {
                      id: (id) => `user-${id}`,
                    },
                    stringify: {
                      id: (id) => id.replace(/^user-/, ''),
                    },
                }
              },
        },
      };

    return (
        <NavigationContainer 
            ref={navigationRef}
            linking={linking} 
            fallback={<Text>Loading...</Text>} 
        >
            <Stack.Navigator screenOptions={{ headerShown: false }} >
                <Stack.Screen name='Home' options={{ title: 'Expo Push Notifications | React Native Expo Push Notifications - Native Notify' }} >
                    {/* {(props) => (
                        <Home {...props}
                            host={host}
                            userId={userId}
                            setUserId={setUserId}
                            setUserInfo={setUserInfo} 
                            setValidationCode={setValidationCode}
                        />
                    )} */}
                    {(props) => (
                        <Login {...props}
                            host={host}
                            userId={userId}
                            setUserId={setUserId} 
                            expoToken={expoToken}
                            RadialGradientBackground={RadialGradientBackground}
                        />
                    )}
                </Stack.Screen>

                <Stack.Screen name='Admin' options={{ title: 'Admin portal' }} >
                    {(props) => (
                        <Admin {...props}
                            host={host}
                        />
                    )}
                </Stack.Screen>

                <Stack.Screen name='IntroDiscount' options={{ title: 'Native Notify Intro Discount' }} >
                    {(props) => (
                        <IntroDiscount {...props}
                            host={host}
                            userId={userId}
                            setUserId={setUserId}
                        />
                    )}
                </Stack.Screen>

                <Stack.Screen name='Dashboard' options={{ title: 'Dashboard' }}>
                    {(props) => (
                        <Dashboard {...props}
                            host={host}
                            userId={userId}
                            setUserId={setUserId}
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                            expoToken={expoToken}  
                            appInfo={appInfo}
                            setAppInfo={setAppInfo}
                            appUpdated={appUpdated}
                            setAppUpdated={setAppUpdated}
                        />
                    )}
                </Stack.Screen>

                <Stack.Screen name='Upgrade' options={{ title: 'Upgrade' }}>
                    {(props) => (
                        <Upgrade {...props}
                            host={host}
                            userId={userId}
                            setUserId={setUserId}
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                            expoToken={expoToken}  
                            appInfo={appInfo}
                            setAppInfo={setAppInfo}
                            appUpdated={appUpdated}
                            setAppUpdated={setAppUpdated}
                        />
                    )}
                </Stack.Screen>

                <Stack.Screen name='ChooseAFramework' options={{ title: 'Choose a Framework' }}>
                    {(props) => (
                        <ChooseAFramework {...props}
                            host={host}
                            userId={userId}
                            setUserId={setUserId}
                            appInfo={appInfo}
                            setAppInfo={setAppInfo}
                            appUpdated={appUpdated}
                            setAppUpdated={setAppUpdated}
                        />
                    )}
                </Stack.Screen>

                <Stack.Screen name='InApp' options={{ title: 'In App' }}>
                    {(props) => (
                        <InApp {...props}
                            host={host}
                            userId={userId}
                            setUserId={setUserId}
                            expoToken={expoToken}  
                            appInfo={appInfo}
                            setAppInfo={setAppInfo}
                            appUpdated={appUpdated}
                            setAppUpdated={setAppUpdated}
                        />
                    )}
                </Stack.Screen>

                <Stack.Screen name='InAppFlutter' options={{ title: 'In App Flutter' }}>
                    {(props) => (
                        <InAppFlutter {...props}
                            host={host}
                            userId={userId}
                            setUserId={setUserId}
                            appInfo={appInfo}
                            setAppInfo={setAppInfo}
                            appUpdated={appUpdated}
                            setAppUpdated={setAppUpdated}
                        />
                    )}
                </Stack.Screen>

                <Stack.Screen name='InAppWebsiteToApp' options={{ title: 'In App Website to App' }}>
                    {(props) => (
                        <InAppWebsiteToApp {...props}
                            host={host}
                            userId={userId}
                            setUserId={setUserId}
                            appInfo={appInfo}
                            setAppInfo={setAppInfo}
                            appUpdated={appUpdated}
                            setAppUpdated={setAppUpdated}
                        />
                    )}
                </Stack.Screen>

                <Stack.Screen name='FAQ' options={{ title: 'FAQ' }}>
                    {(props) => (
                        <FAQ {...props} 
                            setUserId={setUserId}
                            setAppInfo={setAppInfo}
                            userInfo={userInfo}
                        />
                    )}
                </Stack.Screen>

                <Stack.Screen name='Settings' options={{ title: 'Settings' }}>
                    {(props) => (
                        <Settings {...props} 
                            host={host}
                            userId={userId}
                            setUserId={setUserId}
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                            appInfo={appInfo}
                            setAppInfo={setAppInfo}
                            appUpdated={appUpdated}
                        />
                    )}
                </Stack.Screen>

                <Stack.Screen name='Login' options={{ title: 'Login' }}>
                    {(props) => (
                        <Login {...props}
                            host={host}
                            userId={userId}
                            setUserId={setUserId} 
                            expoToken={expoToken}
                            RadialGradientBackground={RadialGradientBackground}
                        />
                    )}
                </Stack.Screen>

                <Stack.Screen name='SignUpOne' options={{ title: 'Sign Up' }}>
                    {(props) => (
                        <SignUpOne {...props}
                            host={host}
                            setUserInfo={setUserInfo} 
                            setValidationCode={setValidationCode} 
                            setUserId={setUserId}
                            RadialGradientBackground={RadialGradientBackground}
                        />
                    )}
                </Stack.Screen>
                <Stack.Screen name='SignUpTwo' options={{ title: 'Sign Up' }}>
                    {(props) => (
                        <SignUpTwo {...props}
                            host={host}
                            validationCode={validationCode} 
                            userInfo={userInfo} 
                            setUserInfo={setUserInfo}
                            userId={userId}
                            setUserId={setUserId} 
                            RadialGradientBackground={RadialGradientBackground}
                        />
                    )}
                </Stack.Screen>
                <Stack.Screen name='ResetPasswordOne' options={{ title: 'Reset Password' }}>
                    {(props) => (
                        <ResetPasswordOne {...props}
                            host={host}
                            expoToken={expoToken}
                            setUserId={setUserId} 
                            RadialGradientBackground={RadialGradientBackground}
                        />
                    )}
                </Stack.Screen>
            </Stack.Navigator>
        </NavigationContainer>
    );
}
