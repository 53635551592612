import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, ScrollView, TextInput, ActivityIndicator } from 'react-native';
import axios from 'axios';

import Dropzone from './Components/Dropzone';

export default function Settings({ host, appInfo, setAppInfo, updateAppInfo, setAppUpdated, navigation }) {
    const { firebase_project_id, google_cloud_access_key_json_file, fcm_server_key, apn_p8_file_name, apn_key_id, apn_team_id, apn_bundle_id } = appInfo;
    // console.log(fcm_server_key, apn_p8_file_name, apn_key_id, apn_team_id, apn_bundle_id);

    // FCM legacy
    const [FcmServerKey, setFcmServerKey] = useState('');

    // FCM v1
    const [FcmV1ProjectId, setFcmV1ProjectId] = useState('');
    const [uploadedFileName, setUploadedFileName] = useState(null);
    const [googleCATJson, setGoogleCATJson] = useState({});

    const [apnP8FileName, setApnP8FileName] = useState();
    const [apnKeyId, setApnKeyId] = useState('');
    const [apnTeamId, setApnTeamId] = useState('');
    const [apnBundleId, setApnBundleId] = useState('');

    const [deleting, setDeleting] = useState(false)

    useEffect(() => {
        firebase_project_id ? setFcmV1ProjectId(firebase_project_id) : null;
        google_cloud_access_key_json_file
            ? (
                setGoogleCATJson(google_cloud_access_key_json_file), 
                setUploadedFileName(google_cloud_access_key_json_file.project_id)
              )
            : null;
        fcm_server_key ? setFcmServerKey(fcm_server_key) : null;
        apn_p8_file_name ? setApnP8FileName(apn_p8_file_name) : null;
        apn_key_id ? setApnKeyId(apn_key_id) : null;
        apn_team_id ? setApnTeamId(apn_team_id) : null;
        apn_bundle_id ? setApnBundleId(apn_bundle_id) : null;
    }, []);

    // need to add inputs for the project id and the googleCATJson in the return below
    const updateSettings = () => {
        axios
            .put(`${host}/api/settings`, { 
                appID: appInfo.app_id, 
                FcmV1ProjectId,
                googleCATJson,
                FcmServerKey,
                apnP8FileName, 
                apnKeyId, 
                apnTeamId, 
                apnBundleId 
            })
            .then(() => {
                updateAppInfo();

                alert('Update successful!');
            })
            .catch(err => console.log(err))
    }

    const handleFileUpload = (jsonData) => {
        // Handle the uploaded JSON file data
        console.log(jsonData);
        setGoogleCATJson(jsonData);
    };

    const deleteApp = async () => {
        let question = confirm("Are you sure you want to delete your app? This cannot be undone!");
        if(question) {
            setDeleting(true);
            await axios   
                .delete(`${host}/api/app/${appInfo.app_id}/${appInfo.app_token}/${appInfo.user_id}`)
                .then(res => {
                    // setApps(res.data)
                    setAppUpdated(0)
                    setAppInfo({});
                    setDeleting(false);
                    navigation.navigate('Dashboard');
                })
                .catch(err => console.log(err))
        } 
    }

    return (
        <ScrollView style={styles.body}>

            <View style={styles.container}>
                <Text style={styles.HeadingText}>Update Settings Warnings:</Text>
                <Text style={styles.subheaderText}>Once you update settings, Expo Go push notifications will stop working.</Text>
                <Text style={styles.subheaderText}>Once you update settings, you will have to test your push notifications on an actual apk app on your Android device or using TestFlight on an iOS device.</Text>
                <Text style={styles.subheaderTextBottom}>It is suggested that you wait to update settings until you are ready to test notifications on an actual device or are ready for production.</Text>

                <Text style={styles.subheaderText}>The information below MUST be correct, or it will break your push notifications.</Text>
                <Text style={styles.subheaderText}>If you do NOT have the correct information for the inputs below, leave the inputs blank.</Text>
                <Text style={styles.subheaderText}>If you have input values in the inputs below and push notifications are not working, check your inputs for typos. A typo will break your push notifications.</Text>
                <Text style={styles.subheaderText}>If your inputs don't have any typos, make sure you are using the native-notify package version 3.0.1 or higher.</Text>
                <Text style={styles.subheaderText}>If your inputs don't have any typos and you are using the native-notify package version 3.0.1 or higher, try to uninstall and then reinstall your app on your device so that your device will create a fresh push notification token.</Text>
            </View>

            <View style={styles.container}>
                <Text style={styles.HeadingText}>Android Push Notifications Settings (Legacy):</Text>
                <Text style={styles.text}>You no longer need to input your FCM information below. These settings were used with an older version of Native Notify. We now use the new EAS Build system for setting up Android Push Notifications. Please view the Android documentation to see how to set up Android Push Notifications the new EAS Build way.</Text>

                <View style={styles.rowCol}>
                    <Text style={styles.text}>FCM Project ID:</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setFcmV1ProjectId(text)}
                        placeholder={"Paste FCM Project ID here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={FcmV1ProjectId}
                    />
                </View>

                <View style={styles.rowCol}>
                    <Text style={styles.text}>Google Cloud Access Token JSON file:</Text>

                    {/* take the uploadedFileName varibles */}
                    <Dropzone onFileUpload={handleFileUpload} uploadedFileName={uploadedFileName} setUploadedFileName={setUploadedFileName} />
                </View>
                
                <View style={styles.rowCol}>
                    <Text style={styles.text}>FCM Legacy Server Key (NOT needed if FCM Project ID and Google Cloud Access Token JSON provided):</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setFcmServerKey(text)}
                        placeholder={"Paste FCM Server Key here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={FcmServerKey}
                    />
                </View>

                <TouchableOpacity style={styles.button} onPress={() => updateSettings()}>
                    <Text style={styles.buttonText}>Update Settings</Text>
                </TouchableOpacity>
            </View>

            <View style={styles.container}>
                <Text style={styles.HeadingText}>iOS Push Notifications Settings (Legacy):</Text>
                <Text style={styles.text}>You no longer need to input your iOS information below. These settings were used with an older version of Native Notify. We now use the new EAS Build system for setting up iOS Push Notifications. Please view the iOS documentation to see how to set up iOS Push Notifications the new EAS Build way.</Text>
                {apn_p8_file_name
                    ? <View>
                        <Text style={styles.text}>An Apple .p8 File has already been uploaded ({apnP8FileName}).</Text>
                        <View style={styles.row}>
                            <Text style={styles.rowText}>Upload a new Apple .p8 File:</Text>
                            <iframe style={{ height: 100, width: '100%', border: 0 }} src="https://p8storage.nativenotify.com"></iframe>
                        </View>
                      </View>
                    : <View style={styles.row}>
                        <Text style={styles.rowText}>Upload an Apple .p8 File:</Text>
                        <iframe style={{ height: 100, width: '100%', border: 0 }} src="https://p8storage.nativenotify.com"></iframe>
                      </View>
                }
                
                <View style={styles.row}>
                    <Text style={styles.rowText}>Apple .p8 File Name:</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setApnP8FileName(text)}
                        placeholder={"Paste Apple .p8 File Name here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={apnP8FileName}
                    />
                </View>
                <View style={styles.row}>
                    <Text style={styles.rowText}>Apple Auth Key ID:</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setApnKeyId(text)}
                        placeholder={"Paste Apple Auth Key ID here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={apnKeyId}
                    />
                </View>
                <View style={styles.row}>
                    <Text style={styles.rowText}>Apple Team ID:</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setApnTeamId(text)}
                        placeholder={"Paste Apple Team ID here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={apnTeamId}
                    />
                </View>
                <View style={styles.row}>
                    <Text style={styles.rowText}>Apple Bundle Identifier:</Text>
                    <TextInput
                        style={styles.inputLarge}
                        onChangeText={(text) => setApnBundleId(text)}
                        placeholder={"Paste Apple Bundle Identifier here..."}
                        placeholderTextColor={"#6e6d6c"}
                        value={apnBundleId}
                    />
                </View>

                <TouchableOpacity style={styles.button} onPress={() => updateSettings()}>
                    <Text style={styles.buttonText}>Update Settings</Text>
                </TouchableOpacity>
            </View>      

            <View style={styles.container}>
                <Text style={styles.HeadingTextRed}>DANGER ZONE!</Text>

                <Text style={styles.text}>If you delete this app, all data will be lost forever. This cannot be undone!</Text>

                <TouchableOpacity 
                    style={styles.buttonRed} onPress={() => deleteApp()}
                    disabled={deleting}
                >
                    {deleting
                        ? <ActivityIndicator size="small" color="white" />
                        : <Text style={styles.buttonText}>Delete This App</Text>
                    }
                </TouchableOpacity>
            </View>      
        </ScrollView>
    )
}

const styles = StyleSheet.create({
    body: {
        width: '85%',
        paddingTop: 30,
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingBottom: 100
    },
    container: {
        width: '100%',
        backgroundColor: '#fff',
        padding: 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        borderRadius: 6,
        marginBottom: 20
    },
    HeadingText: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 24,
        marginBottom: 10
    },
    HeadingTextRed: {
        fontFamily: "Arial",
        fontWeight: 600,
        fontSize: 24,
        marginBottom: 10,
        color: 'red'
    },
    subheaderText: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 5
    },
    subheaderTextBottom: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 30
    },
    button: {
        width: 240,
        backgroundColor: 'rgb(54, 117, 212)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 20
    },
    buttonRed: {
        width: 240,
        backgroundColor: 'red',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 7,
        paddingBottom: 7,
        borderRadius: 6,
        boxShadow: '0px 2px 5px rgb(214,214,214)',
        marginTop: 20
    },
    buttonText: {
        fontFamily: 'Arial',
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
    },
    inputCont: {
        marginBottom: 20,
        width: '100%',
    },
    inputLarge: {
        width: '100%',
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    text: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 10,
    },
    blueText: {
        color: "rgb(54, 117, 212)"
    },
    inputLarge: {
        width: '100%',
        padding: 12.5,
        paddingLeft: 15,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: "#99999920",
        backgroundColor: "#fff",
        borderRadius: 6,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
        marginBottom: 15
    },
    row: {
        flexDirection: "row",
        alignItems: 'center',
        marginTop: 10
    },
    rowCol: {
        flexDirection: 'column',
        marginTop: 10
    },
    rowText: {
        width: 270,
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 10,
        marginRight: 20
    }
    ,
    text: {
        fontFamily: "Arial",
        fontWeight: 400,
        fontSize: 16,
        marginBottom: 10,
        marginRight: 20
    }
});